const { createSlice } = require("@reduxjs/toolkit");

const orderSlice = createSlice({
  name: "order",
  initialState: {
    rate: 0,
    orderItems: [],
  },
  reducers: {
    addOrder(state, action) {
      const product = action.payload;
      state.orderItems = product.product;
      state.rate = product.rate;
    },
  },
});

export const { addOrder } = orderSlice.actions;
export default orderSlice.reducer;

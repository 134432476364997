import { variables } from "../../variables";
import cogoToast from "cogo-toast";

const { createSlice } = require("@reduxjs/toolkit");

const widgetSlice = createSlice({
  name: "widget",
  initialState: {
    slides: [],
    contents: [],
  },
  reducers: {
    setSlides(state, action) {
      state.slides = action.payload.slides;
    },
    setContents(state, action) {
      state.contents = action.payload;
    },
    clearSlides(state, action) {
      return (state = {
        slides: [],
      });
    },
  },
});

export const getContents = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        variables.API_URL + "Content/GetAllContents"
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      cogoToast.error(error.Message || "Something went wrong", {
        position: "bottom-left",
      });
    }
  };
};

export const { setSlides, clearSlides, setContents } = widgetSlice.actions;
export default widgetSlice.reducer;

const { createSlice } = require("@reduxjs/toolkit");

const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
  },
  reducers: {
    setProducts(state, action) {
    //   const newProducts = action.payload;

    //   newProducts.forEach((newProduct) => {
    //     const product = state.products.find(
    //       (oldProduct) => oldProduct.id === newProduct.id
    //     );
    //     if (!product) state.products.push(product);
    //   });
    },
  },
});

export const { setProducts } = productSlice.actions;
export default productSlice.reducer;

import { variables } from "../../variables";

const { createSlice } = require("@reduxjs/toolkit");

const credentialSlice = createSlice({
  name: "credential",
  initialState: {
    isLogged: false,
    guestId: "",
    customerId: 0,
    customerToken: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    purchasedProducts: [],
  },
  reducers: {
    setCredential(state, action) {
      const isLogged = action.payload.isLogged;
      const id = action.payload.guestId;
      const customer = action.payload.customerId;
      const customerToken = action.payload.customerToken;
      const firstName = action.payload.firstName;
      const lastName = action.payload.lastName;
      const phoneNumber = action.payload.phoneNumber;
      const email = action.payload.email;
      const purchasedProducts = action.payload.purchasedProducts;

      if (isLogged) {
        return (state = {
          isLogged: true,
          guestId: id,
          customerId: customer,
          customerToken: customerToken,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          email: email,
          purchasedProducts: purchasedProducts,
        });
      }
      if (!isLogged) {
        return (state = {
          isLogged: false,
          guestId: id,
          customerId: customer,
          customerToken: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          purchasedProducts: [],
        });
      }
    },
  },
});

export const GenerateGuestId = () => {
  return (dispatch) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
};

export const CheckTokenExpiry = (Token, Id) => {
  return async (dispatch) => {
    const response = await fetch(
      variables.API_URL + "Customer/CheckCustomerLoginToken",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: Id,
          customerToken: Token,
        }),
      }
    );
    const result = await response.json();
    return result;
  };
};

export const GetCartItem = (customerId, guestId, offset, currencyId) => {
  return async (dispatch) => {
    if (customerId !== 0) {
      const response = await fetch(
        variables.API_URL +
          "Cart/GetCartItems/Offset/" +
          offset +
          "/Country/237/Group/2/Currency/" +
          currencyId +
          "?CustomerId=" +
          customerId,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      return result;
    } else {
      const response = await fetch(
        variables.API_URL +
          "Cart/GetCartItems/Offset/" +
          offset +
          "/Country/237/Group/1/Currency/" +
          currencyId +
          "?GuestId=" +
          guestId,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      return result;
    }
  };
};

export const { setCredential } = credentialSlice.actions;
export default credentialSlice.reducer;

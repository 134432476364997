const { createSlice } = require("@reduxjs/toolkit");

const currencySlice = createSlice({
  name: "currency",
  initialState: {
    currencyId: 1,
    currencySymbol: "AED",
  },
  reducers: {
    setCurrency(state, action) {
      return (state = {
        currencyId: action.payload.currencyId,
        currencySymbol: action.payload.currencySymbol,
      });
    },
  },
});

export const { setCurrency } = currencySlice.actions;
export default currencySlice.reducer;